import mapValues from 'lodash/mapValues'
import { useCookies } from 'react-cookie'

export type Beta = 'scene' | 'soundcheck'

export function useBeta(name: Beta): boolean {
  const [cookies] = useCookies([name])

  return cookies[name] === 'true'
}

export function useBetas(names: Beta[]) {
  const [cookies] = useCookies(names)

  return mapValues(cookies, (value) => value === 'true') as Record<
    Beta,
    boolean
  >
}
